/* clearfix */
.cf:before, .cf:after { content: " "; display: table; }
.cf:after { clear: both; }
.cf { *zoom: 1; }

/* Ding details style fix */

h4.INFO a {
  color: lightblue;
}
h4.WARNING a {
  color: gray;
}
h4.DANGER a {
  color: red;
}

li.user-connected{
  border-bottom: solid 1px #f1f1f1;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
li.user-connected:last-child{
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

ul.custom-pagination{
  position: absolute;
  top: 5px;
  right: 20px;
  margin: 0;
  font-size: 15px;
  padding: 0;
  list-style: none;
}

ul.custom-pagination li.first{
  float:left;
  margin: 5px;
}
ul.custom-pagination li.last{
  float: right;
  margin: 5px;
}
ul.custom-pagination li.current{
    padding: 5px;
    float: left;
}

ul.custom-pagination li > a{
  border: none;
}

ul.custom-pagination li.disabled > a{
  color: #ccc;
}

.ding-modal{
    background: #e4e7ea !important;
}

.widget-messaging .commentForm{
  margin-bottom: 20px;
  border-bottom: solid 1px #e4e4e4;
  padding-bottom: 10px;
}
.widget-messaging .content{
  margin-top: 10px;
  margin-bottom: 0;
}

#dingNotesPanel .pagination{
  margin: 0;
}

#dingNotesPanel .toggleForm{
  color: #1d4f5f;
  font-size: 15px;
  float: right;
  text-transform: uppercase;
}

/* Tags */
.tag_list button{
  margin-right: 5px;
  margin-bottom: 5px;
}

.tag_list h4{
  line-height: 13px;
}

.tag_controls button{
  margin-left: 5px;
}

.tag_list button:last-child, .tag_controls button:last-child{
  margin-right: 0px;
}

tags-input .host {
  position: relative;
}
tags-input .tags .tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

tags-input .tags .input {
  border: 0;
  outline: 0;
  margin: 2px;
  padding: 0;
  padding-left: 5px;
  float: left;
  height: 26px;
  font: 14px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

tags-input .tags .tag-item{
  margin: 2px;
  padding: 0 5px;
  display: inline-block;
  float: left;
  font: 14px "Helvetica Neue",Helvetica,Arial,sans-serif;
  height: 26px;
  line-height: 25px;
  border: 1px solid #acacac;
  border-radius: 3px;
  background: -webkit-linear-gradient(top,#f0f9ff 0,#cbebff 47%,#a1dbff 100%);
  background: linear-gradient(to bottom,#f0f9ff 0,#cbebff 47%,#a1dbff 100%);
}

tags-input .tags {
  padding: 1px;
  overflow: hidden;
  word-wrap: break-word;
  cursor: text;
  background-color: #fff;
  border: 1px solid #ccc;
}

tags-input .tags:focus, tags-input .host:focus{
  outline: none;
}

tags-input .tags .tag-item .remove-button {
  margin: 0 0 0 5px;
  padding: 0;
  border: none;
  background: 0 0;
  cursor: pointer;
  vertical-align: middle;
  font: 700 16px Arial,sans-serif;
  color: #f1f1f1;

}

tags-input .tags .tag-item .remove-button:hover {
  text-decoration: none;
}

tags-input .tags .input.invalid-tag {
  color: #d14836;
}

tags-input .autocomplete .suggestion-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

tags-input .autocomplete .suggestion-item {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: 16px "Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #000;
  background-color: #fff;
}

tags-input .autocomplete .suggestion-item:hover{
  background-color: #f1f1f1;
}
.bootstrap .tags {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.bootstrap .tags.focused {
  border-color: #66afe9;
  outline: none;
}

.bootstrap .tags .tag-item {
  background: #428bca;
  border: 1px solid #357ebd;
  border-radius: 4px;
  color: #fff;
}

.bootstrap .tags .tag-item.selected {
  background: #d9534f;
  border: 1px solid #d43f3a;
  border-radius: 4px;
  color: #fff;
}

.bootstrap .tags .tag-item button {
  background: transparent;
  color: #000;
  opacity: .4;
}

.bootstrap .autocomplete {
  z-index: 100;
  position: absolute;
  width: 100%;
  border-left: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  border-right: solid 1px #ccc;
  border-radius: 0;
}

.bootstrap .autocomplete .suggestion-item.selected {
  color: #262626;
  background-color: #e9e9e9;
}

.bootstrap .autocomplete .suggestion-item em {
  font-weight: normal;
  background-color: #ffff00;
}

.bootstrap .autocomplete .suggestion-item.selected em {
  color: #262626;
  background-color: #ffff00;
}

.filter-form{
  float: right;
  width: 30%;
  margin-top: -10px;
}
.filter-form .bootstrap{
  display: inline-block;
  width: 80%;
}
.filter-form button{
  float: right;
  margin: 5px;
}

/* Server Conf controls */
#server_resume .controls i.disabled,
#customer-details-section .controls i.disabled{
  opacity: 0.5;
}

#server_resume .controls, #customer-details-section .controls{
  font-size: 15px;
}


/* Server Conf input error */
#server_resume table .has-error .form-control,
#server_resume table .has-error .form-control:focus {
  border: solid 1px #a94442;
}

/* Server Conf fix styles */
#server_resume table .form-control {
  padding: 5px;
}

#server_resume table .form-control:focus {
  padding: 5px;
  border-color: #999;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#server_resume table .dropdown-menu {
  padding: 0px;
}
#server_resume table .btn-group .btn.dropdown-toggle{
  padding: 3px;
}
#server_resume small.error{
  color: #a94442;
}

#customer-details-section table td.field-label{
  font-weight: bold;
}

#customer-details-section table td input{
  height: 30px;
}
.columns-menu{
  background: #fff;
  position: absolute;
  top: 40px;
  right: 19px;
  list-style: none;
  margin: 0;
  width: 110px;
  border: solid 1px #ccc;
  box-shadow: 0px 2px 2px #ccc;
  padding: 0;
  z-index: 1;
}

.columns-menu li{
  padding: 0 10px;
  border-bottom: solid 1px #e4e4e4;
  font-size: 12px;
}

.columns-menu li:last-child{
  border-bottom: none;
}

.columns-menu li input{
  margin: 6px;
}

.diff-list{
  margin:0;
  padding:0;
  list-style:none
}

.diff-list li i{
  font-size: 10px;
  margin-right: 10px
}

.last_setup .form-control.disabled{
  background: #eee !important;
}

.reportSpinner{
  margin-right: 15px;
  float: right;
}

#reports_resume td.expand, #setupHistory td.expand{
  text-align: center;
}

#report_resume td, #setupHistory td{
  padding: 5px;
}

#reports_resume td ul, #setupHistory td ul, #doorbotsList td ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

#reports_resume td ul li, #setupHistory td ul li, #doorbotsList td.status ul li{
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: dashed 2px #f1f1f1;
}

#reports_resume td ul li:first-child, #setupHistory td ul li:first-child, #doorbotsList td.status ul li:first-child{
  padding-top: 0;
  border-top: none;
}

#reports_resume td li div:first-child, #setupHistory td li div:first-child, #doorbotsList td ul li div:first-child{
  display: inline-block;
  width: 65%;
}

#reports_resume td li div:last-child, #setupHistory td li div:last-child, #doorbotsList td ul li div:last-child{
  display: inline-block;
  width: 30%;
}

/* CB ADDONS */
.nopadding { padding: 0; }
/* change logo */
.leftpanel { width: 100px!important; }
.leftpanelinner { position: absolute; top: 50px; left: 0; background: #1d2939; z-index: 20; display: none;}
.leftpanel .logopanel h1 { text-align: center; }
.headerbar .menutoggle { display: none; }
/* get rid of the main menu on desktops */
.leftpanelinner { display: none; }
.mainpanel { margin-left: 0!important; }
.mainpanel .headerbar { margin-left: 0; }
/* get rid of avatar */
.headerbar .header-right .headermenu img { display: none; }
.headerbar .header-right .headermenu .loggedusermobile { display: none; }
/* reposition of timezone menu */
.headerbar .header-right .headermenu > li { height: 50px; padding-top: 5px; font-size: 14px; }
.headerbar .header-right .headermenu .btn-group { display: block; float: none; }
.headerbar .header-right .headermenu .timezoneautocomplete { padding: 0!important; line-height: 15px!important; height: 20px!important; font-size: 12px!important; padding-left: 10px!important; }
.headerbar .header-right .headermenu .dropdown-toggle { padding: 5px 10px 0; }
.headerbar .header-right .headermenu .dropdown-toggle:hover, .headerbar .header-right .headermenu .dropdown-toggle:active, .headerbar .header-right .headermenu .dropdown-toggle:focus { background: none; padding: 5px 10px 0; color: #d94d00; }
.headerbar .header-right .headermenu .dropdown-toggle:hover .caret, .headerbar .header-right .headermenu .dropdown-toggle:active .caret, .headerbar .header-right .headermenu .dropdown-toggle:focus { border-top-color: #d94d00; }
.headerbar .header-right .headermenu .tp-icon:hover, .headerbar .header-right .headermenu .tp-icon:active,.headerbar .header-right .headermenu .tp-icon:focus { color: #333; }
.headerbar .header-right .headermenu .dropdown-menu li.ng-scope { padding: 0 5px; }
form.timezonesform { position: absolute; top: 0; right: 0;  width: 100%; }
form.timezonesform input { width: 200px!important; }
form.timezonesform button { position: absolute; top: -2px; right: 0;  height: 52px!important; }
form.timezonesform ul { margin-top: 0!important; }
/* reposition of dropdowns */
.headerbar .header-right .headermenu .dropdown-menu { margin-top: 40px; }
.headerbar .header-right .headermenu .dropdown-menu-usermenu { margin-top: 30px; right: 20px; }
/* reposition / fixes of search */
.headerbar form.searchform { float: right; border-left: 1px solid #eee; }
.headerbar form.searchform input { padding: 18px 0 18px 10px; width: 141px; }
.headerbar form.searchform button { border: none; }
/* move menu to top bar */
.headerbar .headermenu { float: left; }
.headerbar .headermenu ul.nav-bracket { margin-bottom: 0; }
.headerbar .headermenu ul.nav-bracket li { padding-top: 8px; }
.headerbar .headermenu ul.nav-bracket li i { display: none; }
.headerbar .headermenu ul.nav-bracket li a { border-radius: 0; padding-bottom: 12px; }
/* reposition help button */
.headerbar li.help-btn { padding-top: 0!important; }
.headerbar .tp-icon { padding: 16px 15px 10px!important; border-radius: 0; border: none; }
.chat-view .headerbar .chat-icon { background: #1d2939; color: white; border-radius: 0; border: none; }
.chat-view .headerbar .chat-icon:hover { color: white!important; }
/* fixing header */
.headerbar { position: fixed; width: 100%; z-index: 999; height: 50px; box-shadow: 0 0 6px #515151; }
.pageheader { padding: 70px 15px 15px!important; }
.pageheader .breadcrumb-wrapper { top: 95px!important; }
.leftpanel-collapsed .headerbar { margin-left: 0!important; }
.leftpanel-collapsed .logopanel { display: inline-block!important; }
/* help menu */
body.chat-view { margin-right: 0!important; }
.rightpanel { background: #1d2939; z-index: 10; }
.rightpanel h2 { font-size: 18px; color: white; padding: 10px 0px!important; margin: 0!important; text-transform: uppercase; }
.rightpanel h3 { font-size: 14px; color: white; padding: 30px 0 10px!important; margin: 0!important; text-transform: uppercase; }
.rightpanel p { font-size: 12px; }
.rightpanel p strong { color: white; display: block; }

/* misc styles */
td.title{ text-align: right}

.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 0px 0px 0px 0;
}

.uploadFile{
  cursor: pointer !important;
  z-index: 10000;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 10px;
  opacity: 0;
  cursor: pointer !important;
  filter: alpha(opacity=0);
}

/* table actions */
a.edit-row, a.delete-row { margin: 0 5px; }
a.delete-row:hover { color: #d94d00; }

/* Doorbot details */



/* media queries */
@media only screen and ( max-width: 1370px ) {
  ul.nav-horizontal li a i { display: none; }
}

@media only screen and ( max-width: 1260px ) {
  .mainpanel .headerbar { margin-left: 0; }
  .leftpanel { display: none; }
  ul.nav-horizontal li a { padding: 15px 10px 12px!important; }
}

@media only screen and ( max-width: 1170px ) {
  ul.nav-horizontal li a i { display: inline; }
  ul.nav-horizontal li a span { display: none; }
  ul.nav-horizontal li ul li a span { display: inline; }
}

@media screen and (max-width: 860px ) {
  .headermenu .dropdown-menu:after { display: inline-block!important; }
  .headerbar .menutoggle { display: inline-block; }
  .headerbar .headermenu .textmenu, .headerbar .topnav ul.nav, .headermenu li.help-btn { display: none; }
}

@media only screen and ( max-width: 620px ) {
  .headerbar .header-right .headermenu button.loggeduser { display: none; }
  .headerbar .header-right .headermenu button.loggedusermobile { display: block; font-size: 15px; width: 50px; height: 50px; border-left: 1px solid #eee; }
  .headerbar .header-right .headermenu button.loggedusermobile:hover { color: #d94d00; background: #f1f1f1; }
  .headerbar .header-right .headermenu .timezone-selectors { display: none; }
  .headermenu .dropdown-menu:after {
    display: none!important;
  }
}

#billing_resume .ready{
  color: green
}

#billing_resume .cancelled{
  color: red
}

#billing_resume .due{
  color: yellow
}

#billing_resume h3 span{
  color: gray;
  text-transform: uppercase;
}

.opacity-hide {
  opacity: 0;
}

.check-container {
  border-style: solid;
  border-width: 3px;
  border-radius: 5px;
  border-color: #428BCA;
  display: inline-block;
  padding: 1px;
}

.check-mark {
  margin-top: 1px;
  margin-right: 1px;
}

.liveviewprofile-message {
  color: #D94D00;
  text-align: justify;
  margin-bottom: 15px;
}

.no-x-scroll {
  overflow-x: hidden !important;
}

.popover-content {
  word-break: normal !important;
}